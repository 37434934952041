<!-- eslint-disable vue/html-closing-bracket-newline -->
<template>
  <div v-if="permission.access">
    <div class="row">
      <div class="col-md-8">
        <b-card no-body>
          <div class="m-2">
            <!-- Table Top -->
            <b-row>
              <!-- Per Page -->
              <b-col
                cols="12"
                md="6"
                class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
              >
                <label>Show</label>
                <!-- :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" -->
                <v-select
                  @on-change="paginateChange"
                  :options="perPageOptions"
                  v-model="search.paginate"
                  :clearable="false"
                  class="per-page-selector d-inline-block mx-50"
                />
                <label>entries</label>
              </b-col>

              <!-- Search -->
              <b-col cols="12" md="6">
                <div class="d-flex align-items-center justify-content-end">
                  <b-form-input
                    v-model="search.search_data"
                    class="d-inline-block mr-1"
                    placeholder="Search..."
                  />
                </div>
              </b-col>
            </b-row>
          </div>
          <div class="table-responsive">
            <table class="table">
              <thead>
                <tr>
                  <th>Sl</th>
                  <th>Name</th>
                  <th class="text-center align-middle">Date</th>
                  <th class="text-center align-middle">Slot</th>
                  <th>Venue</th>
                  <th class="text-center">Status</th>
                  <th class="text-center" v-if="permission.edit">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr
                  v-for="(seminar, index) in seminars.data"
                  :key="index"
                  v-if="seminars.data.length"
                >
                  <td class="align-middle text-center">
                    {{ seminars.from + index }}
                  </td>
                  <td>
                    <p class="mb-0">
                      {{ seminar.title }}
                      <!-- <span v-if="seminar.course">{{
                        seminar.course.name_bn
                      }}</span> -->
                    </p>
                  </td>
                  <td>{{ seminar.date | date_format }}</td>
                  <td class="text-center align-middle">
                    {{
                      seminar.slot == 1
                        ? "সকাল"
                        : seminar.slot == 2
                        ? "দুপুর"
                        : seminar.slot == 3
                        ? "বিকাল"
                        : seminar.slot == 4
                        ? "সন্ধ্যা"
                        : "রাত"
                    }}
                    {{ seminar.hour + "টা" }}
                    {{ seminar.minute ? seminar.minute + "মিনিট" : "" }}
                  </td>
                  <td class="align-middle">
                    {{
                      seminar.venue == 1
                        ? "ক্রিয়েটিভ আইটি হেড অফিস"
                        : seminar.venue == 2
                        ? "ক্রিয়েটিভ আইটি চট্টগ্রাম অফিস"
                        : seminar.venue == 3
                        ? "ক্রিয়েটিভ আইটি উত্তরা অফিস"
                        : seminar.venue == 4
                        ? "অনলাইন"
                        : seminar.other_venue
                    }}
                  </td>
                  <td class="align-middle text-center">
                    <span
                      class="badge"
                      :class="
                        seminar.status == 1 ? 'badge-success' : 'badge-danger'
                      "
                    >
                      {{ seminar.status == 1 ? "Active" : "Inactive" }}
                    </span>
                  </td>
                  <td class="align-middle text-center" v-if="permission.edit">
                    <button
                      class="btn btn-primary btn-sm"
                      @click="edit(seminar.id)"
                    >
                      Edit
                    </button>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
          <div class="mx-2 mb-2">
            <b-row>
              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-start"
              >
                <span class="text-muted"
                  >Showing {{ seminars.from ? seminars.from : 0 }} to
                  {{ seminars.to ? seminars.to : 0 }} of
                  {{ seminars.total ? seminars.total : 0 }}
                  entries</span
                >
              </b-col>

              <b-col
                cols="12"
                sm="6"
                class="d-flex align-items-center justify-content-center justify-content-sm-end"
              >
                <pagination
                  :data="seminars"
                  @pagination-change-page="getResults"
                  align="right"
                  :show-disabled="true"
                  :limit="5"
                ></pagination>
              </b-col>
            </b-row>
          </div>
        </b-card>
      </div>
      <div class="col-md-4" v-if="permission.create || form.id != ''">
        <b-card :title="(form.id == '' ? 'Create' : 'Update') + ' Seminar'">
          <b-card-text
            ><validation-observer ref="loginValidation">
              <b-form
                class="auth-login-form mt-2"
                @submit.prevent
                enctype="multipart/form-data"
              >
                <b-form-group label="Seminar Title" label-for="title">
                  <validation-provider
                    #default="{ errors }"
                    name="title"
                    rules="required"
                  >
                    <b-form-input
                      id="title"
                      v-model="form.title"
                      :state="errors.length > 0 ? false : null"
                      name="title"
                      placeholder="Enter Your Seminar Title"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Select Course" label-for="course_id">
                  <validation-provider
                    #default="{ errors }"
                    name="course_id"
                    rules="required"
                  >
                    <v-select
                      id="course_id"
                      v-model="form.course_id"
                      placeholder="Select Course"
                      :options="courses"
                      label="name_en"
                      :reduce="(option) => option.id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>
                <b-form-group label="Seminar Date" label-for="seminar_date">
                  <validation-provider
                    #default="{ errors }"
                    name="seminar_date"
                    rules="required"
                  >
                    <flat-pickr
                      v-model="form.date"
                      class="form-control"
                      :state="errors.length > 0 ? false : null"
                      placeholder="Select Seminar Date"
                      :config="{
                        enableTime: true,
                        dateFormat: 'Y-m-d H:i',
                      }"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Select Time Slot" label-for="slot">
                  <validation-provider
                    #default="{ errors }"
                    name="slot"
                    rules="required"
                  >
                    <v-select
                      id="slot"
                      v-model="form.slot"
                      placeholder="Select Time Slot"
                      :options="slots"
                      label="name"
                      :reduce="(option) => option.id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Select Hour" label-for="hour">
                  <validation-provider
                    #default="{ errors }"
                    name="hour"
                    rules="required"
                  >
                    <v-select
                      id="hour"
                      v-model="form.hour"
                      placeholder="Select Time Hour"
                      :options="hours"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Select Minutes" label-for="minute">
                  <validation-provider
                    #default="{ errors }"
                    name="minute"
                    rules="required"
                  >
                    <v-select
                      id="minute"
                      v-model="form.minute"
                      placeholder="Select Time Minute"
                      :options="minutes"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group label="Select Venue" label-for="venue">
                  <validation-provider
                    #default="{ errors }"
                    name="venue"
                    rules="required"
                  >
                    <v-select
                      id="venue"
                      v-model="form.venue"
                      placeholder="Select Venue"
                      :options="venues"
                      label="name"
                      :reduce="(option) => option.id"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <b-form-group
                  label="Other Venue"
                  label-for="other_venue"
                  v-if="form.venue == 5"
                >
                  <validation-provider
                    #default="{ errors }"
                    name="other_venue"
                    rules="required"
                  >
                    <b-form-input
                      id="other_venue"
                      v-model="form.other_venue"
                      :state="errors.length > 0 ? false : null"
                      name="other_venue"
                      placeholder="Enter Your Other Venue"
                    />
                    <small class="text-danger">{{ errors[0] }}</small>
                  </validation-provider>
                </b-form-group>

                <!-- submit buttons -->
                <b-form-checkbox
                  name="status"
                  style="margin-top: 5px"
                  v-model="form.status"
                  switch
                  inline
                >
                </b-form-checkbox>
                <b-button
                  type="submit"
                  class="float-right"
                  variant="primary"
                  @click="validationForm"
                >
                  {{ form.id == "" ? "Create" : "Update" }}
                </b-button>
                <b-button
                  type="submit"
                  class="float-right mx-1"
                  variant="outline-primary"
                  @click="Close"
                >
                  Close
                </b-button>
              </b-form>
            </validation-observer></b-card-text
          >
        </b-card>
      </div>
    </div>
  </div>
</template>

<script>
import {
  BTabs,
  BTab,
  BCard,
  BMediaBody,
  BMediaAside,
  BMedia,
  BFormFile,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BInputGroupAppend,
  BInputGroup,
  BFormCheckbox,
  BCardText,
  BCardTitle,
  BImg,
  BForm,
  BButton,
  BFormTextarea,
} from "bootstrap-vue";
import { required } from "@validations";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import flatPickr from "vue-flatpickr-component";
import vSelect from "vue-select";
import _ from "lodash";
export default {
  data() {
    return {
      search: {
        search_data: "",
        paginate: 10,
        page: 1,
      },
      slots: [
        { name: "সকাল", id: 1 },
        { name: "দুপুর", id: 2 },
        { name: "বিকাল", id: 3 },
        { name: "সন্ধ্যা", id: 4 },
        { name: "রাত", id: 5 },
      ],
      venues: [
        { name: "ক্রিয়েটিভ আইটি হেড অফিস", id: 1 },
        { name: "ক্রিয়েটিভ আইটি চট্টগ্রাম অফিস", id: 2 },
        { name: "ক্রিয়েটিভ আইটি উত্তরা অফিস", id: 3 },
        { name: "অনলাইন", id: 4 },
        { name: "অন্যান্য", id: 5 },
      ],
      perPageOptions: [10, 25, 50, 100],
      hours: [
        "০১",
        "০২",
        "০৩",
        "০৪",
        "০৫",
        "০৬",
        "০৭",
        "০৮",
        "০৯",
        "১০",
        "১১",
        "১২",
      ],
      minutes: [
        "০০",
        "০১",
        "০২",
        "০৩",
        "০৪",
        "০৫",
        "০৬",
        "০৭",
        "০৮",
        "০৯",
        "১০",
        "১১",
        "১২",
        "১৩",
        "১৪",
        "১৫",
        "১৬",
        "১৭",
        "১৮",
        "১৯",
        "২০",
        "২১",
        "২২",
        "২৩",
        "২৪",
        "২৫",
        "২৬",
        "২৭",
        "২৮",
        "২৯",
        "৩০",
        "৩১",
        "৩২",
        "৩৩",
        "৩৪",
        "৩৫",
        "৩৬",
        "৩৭",
        "৩৮",
        "৩৯",
        "৪০",
        "৪১",
        "৪২",
        "৪৩",
        "৪৪",
        "৪৫",
        "৪৬",
        "৪৭",
        "৪৮",
        "৪৯",
        "৫০",
        "৫১",
        "৫২",
        "৫৩",
        "৫৪",
        "৫৫",
        "৫৬",
        "৫৭",
        "৫৮",
        "৫৯",
      ],
      form: {
        id: "",
        course_id: null,
        title: "",
        date: "",
        slot: "",
        hour: "",
        minute: "",
        venue: "",
        other_venue: "",
        status: true,
      },
      seminars: {},
      permission: {
        access: false,
        create: false,
        edit: false,
        delete: false,
      },
    };
  },
  mounted() {
    this.getResults();
    this.$store.dispatch("getCourse");
    this.Permission();
  },
  methods: {
    async getResults(page) {
      this.search.page = page == null ? this.search.page : page;
      const seminars = await this.callApi(
        "post",
        "/app/seminar?page=" + this.search.page,
        this.search
      );
      if (seminars.status == 200) {
        this.seminars = seminars.data.seminars;
      }
    },

    Close() {
      this.form.id = "";
      this.form.course_id = null;
      this.form.title = "";
      this.form.date = "";
      this.form.slot = "";
      this.form.hour = "";
      this.form.minute = "";
      this.form.venue = "";
      this.form.other_venue = "";
      this.form.status = true;
    },

    async edit(id) {
      const seminar = await this.callApi("post", "/app/seminar/edit/" + id);
      if (seminar.status == 200) {
        this.form.id = seminar.data.seminar.id;
        this.form.title = seminar.data.seminar.title;
        this.form.course_id = seminar.data.seminar.course_id;
        this.form.date = seminar.data.seminar.date;
        this.form.slot = seminar.data.seminar.slot;
        this.form.hour = seminar.data.seminar.hour;
        this.form.minute = seminar.data.seminar.minute;
        this.form.venue = seminar.data.seminar.venue;
        this.form.other_venue = seminar.data.seminar.other_venue;
        this.form.status = seminar.data.seminar.status == true ? true : false;
      }
    },

    add() {
      axios
        .post("/app/seminar/store", this.form)
        .then((res) => {
          this.getResults();
          this.Close();
          this.s(res.data.message);
        })
        .catch((e) => {
          if (e.response.status === 422) {
            // if (e.response.data.errors.icon_image)
            //   this.e(e.response.data.errors.icon_image[0]);
          }
        });
    },

    validationForm() {
      this.$refs.loginValidation.validate().then((success) => {
        if (success) {
          this.add();
        }
      });
    },

    SearchData: _.debounce(function () {
      this.search.page = 1;
      this.getResults();
    }, 1000),

    // Paginate Data Gate
    paginateChange() {
      this.search.page = 1;
      this.getResults();
    },

    async Permission() {
      const permissions = await this.callApi("post", "/app/permission");
      permissions.data.permissions.forEach((permission) => {
        if (permission.slug == "seminar.access") {
          this.permission.access = true;
        }

        if (permission.slug == "seminar.create") {
          this.permission.create = true;
        }

        if (permission.slug == "seminar.edit") {
          this.permission.edit = true;
        }

        if (permission.slug == "seminar.destroy") {
          this.permission.delete = true;
        }
      });
    },
  },
  watch: {
    "search.search_data": function (val) {
      this.SearchData();
    },
    "search.paginate": function (val) {
      this.paginateChange();
    },
  },
  computed: {
    courses() {
      return this.$store.getters.getCourse;
    },
  },
  components: {
    flatPickr,
    vSelect,
    BCard,
    BCardText,
    BLink,
    BTabs,
    BTab,
    BMedia,
    BFormFile,
    BFormTextarea,
    BRow,
    BCol,
    BLink,
    BFormGroup,
    BFormInput,
    BInputGroupAppend,
    BInputGroup,
    BFormCheckbox,
    BCardText,
    BCardTitle,
    BImg,
    BForm,
    BButton,
    ValidationProvider,
    ValidationObserver,
    BMediaAside,
    BMediaBody,
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
